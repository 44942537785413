import axios from 'axios';
import { toast } from 'react-toastify';
import Keys from './keys';
const error_notify = (text) => toast.error(text ? text : "error", {
  position: toast.POSITION.TOP_CENTER
});
export const setApiAuthorizationHeader = (token) => {

  authApi.defaults.headers.common.Authorization = token
    ? `Token ${token}`
    : null;
};
const authApi = axios.create({
  baseURL: Keys.SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    // "Authorization": `Token ${}`
  }

});
axios.interceptors.request.use(config => {
  config.timeout = 2000; // Wait for 5 seconds before timing out
  return config;
});
authApi.interceptors.response.use(
  (res) => {

    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
      error_notify("Someting went wrong");
    }

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired

      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        needLogin();
        try {
          // const rs = await instance.post("/auth/refreshtoken", {
          //   refreshToken: TokenService.getLocalRefreshToken(),
          // });

          // const { accessToken } = rs.data;
          // TokenService.updateLocalAccessToken(accessToken);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export const needLogin = () => {    //petq e
  document.cookie = `token=""; path=/`;
  document.cookie = `user_id=""; path=/`;
  document.cookie = `lat=""; path=/`;
  document.cookie = `lng=""; path=/`;
  if (window.location.pathname !== "/" && window.location.pathname !== "/login") {
    window.location = "/login";
  }
};
export default authApi;